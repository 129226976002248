import React, { useState } from 'react';
import Modal from 'react-modal';
import { useAppContext } from '../../context/AppContext';
import OmMeg from '../../pages/Profile/sections/OmMeg';
import BateButton, { ArrowType } from '../Elements/BateButton';

const NudgeProfileConfirmationModal: React.FC = () => {
  const { user, contactInformationShouldBeConfirmed } = useAppContext();
  const [imageRefresh, setImageRefresh] = useState<number>(0);
  const [modalIsOpen, setIsOpen] = useState(true);

  if (!user || !contactInformationShouldBeConfirmed) return null;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingRight: '50px',
      paddingLeft: '50px',
      display: 'flex',
      // eslint-disable-next-line @typescript-eslint/prefer-as-const
      flexDirection: 'column' as 'column',
      gap: 15,
    },
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <BateButton
        onClick={() => setIsOpen(false)}
        className="self-end"
        arrowType={ArrowType.None}
        small
      >
        Lukk
      </BateButton>
      <h2>
        Har vi riktig opplysninger om deg?
      </h2>
      <OmMeg setImageRefresh={setImageRefresh} allowNonDirtySubmit onUpdated={() => setIsOpen(false)} showEmail hideHelpText hideName />
    </Modal>
  );
};

export default NudgeProfileConfirmationModal;
